/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const InputFieldAction = ({
  stateProp,
  className,
  frameClassName,
  divClassName,
  expandCircleDownClassName,
  expandCircleDown = "/img/expand-circle-down-1.png",
  ellipseClassName,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
  });

  return (
    <div
      className={`input-field-action state-3-${state.state} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {
        dispatch("click");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      {state.state === "default" && (
        <>
          <div className={`frame-2 ${frameClassName}`}>
            <div className={`text-wrapper ${divClassName}`}>eg. Wedding</div>
            <img
              className={`expand-circle-down ${expandCircleDownClassName}`}
              alt="Expand circle down"
              src={expandCircleDown}
            />
          </div>
          <div className={`ellipse-2 ${ellipseClassName}`} />
        </>
      )}

      {["hover", "selected"].includes(state.state) && (
        <div className="frame-wrapper">
          <div className="frame-3">
            {state.state === "selected" && (
              <>
                <div className="text-wrapper">eg. Wedding</div>
                <img className="expand-circle-down" alt="Expand circle down" src="/img/expand-circle-down-1.png" />
              </>
            )}

            {state.state === "hover" && <>|</>}
          </div>
        </div>
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.state === "default") {
    switch (action) {
      case "click":
        return {
          state: "hover",
        };

      case "mouse_enter":
        return {
          state: "selected",
        };
    }
  }

  if (state.state === "selected") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "default",
        };
    }
  }

  return state;
}

InputFieldAction.propTypes = {
  stateProp: PropTypes.oneOf(["selected", "hover", "default"]),
  expandCircleDown: PropTypes.string,
};

import React, { useEffect } from "react";
import { Checkbox } from "../../components/Checkbox";
import { IconsArrowBack } from "../../components/IconsArrowBack";
import { Indicator } from "../../components/Indicator";
import { InputField } from "../../components/InputField";
import { InputFieldAction } from "../../components/InputFieldAction";
import { RadioButton } from "../../components/RadioButton";
import { LeadingIcon2 } from "../../icons/LeadingIcon2";
import "./style.css";

export const Vister = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="vister">
      <div className="div-2">
        <div className="overlap">
          <div className="rectangle" />
          <img
            className="two-iphone-ui-design"
            alt="Two iphone ui design"
            src="/img/two-iphone-ui-design-presentation-mockup-template-64d2b40195f9e8.png"
          />
          <div className="group">
            <div className="UIX-wrapper">
              <div className="UIX">
                <div className="div-wrapper">
                  <div className="frame-4">
                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-2">
                        <img
                          className="line"
                          alt="Line"
                          src="/img/line-18.svg"
                        />
                        <img
                          className="img"
                          alt="Line"
                          src="/img/line-19.svg"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-2">Fintech</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-3">Industry</div>
          </div>
          <div className="the-challenge">
            <div className="frame-5">
              <div className="frame-6">
                <div className="text-wrapper-4">About</div>
                <p className="p">
                  Vister needed a fresh perspective for disbursing personal
                  loans within minutes for credit worthy individuals (or at
                  least a better way to present it) at the click of a button -
                  The usual process is to go to a bank or find financing
                  partners and go through a lengthy series of back-and-forth,
                  endless verifications and much more patience to get the money
                  transferred to your bank account. <br />
                  <br />
                  Enter Vister - That works in a simplified manner - All you
                  need is your PAN number - the backend verifies in minutes,
                  instead of days or hours - and voila, you get the money
                  transferred, well if you qualify, you do - you get the point.
                </p>
              </div>
            </div>
          </div>
          <div className="heading">
            <div className="text-wrapper-5">Vister</div>
            <div className="text-wrapper-6">Personal Loans Simplified</div>
          </div>
        </div>
        <div className="the-challenge-2">
          <div className="frame-7">
            <div className="text-wrapper-7">The Project</div>
            <div className="frame-8">
              <p className="text-wrapper-8">
                A validated hypothesis to simplify lending and borrowing
              </p>
            </div>
          </div>
          <p className="text-wrapper-9">
            Our team sought to create an innovative and engaging app experience
            to redefine how users access personal loans and validate the
            hypothesis. We executed a strategic play to build the clean and
            agile UI, Informative yet reader-friendly copies and interactive
            visual elements to transform complex concepts into simplified
            reasons to get the bucks when needed.
          </p>
        </div>
        <div className="frame-9" />
        <div className="element">
          <div className="frame-10">
            <div className="swatch-primary">
              <div className="frame-11">
                <div className="text-wrapper-10">Calming Blue</div>
                <div className="frame-12">
                  <div className="text-wrapper-11">Hex: #312B96</div>
                  <div className="text-wrapper-11">RGB: 49, 43, 150</div>
                  <p className="text-wrapper-11">CMYK: 67, 71, 0, 41</p>
                </div>
              </div>
            </div>
            <div className="swatch-secondary">
              <div className="frame-13">
                <div className="text-wrapper-10">Ocean Green</div>
                <div className="frame-12">
                  <div className="text-wrapper-11">Hex: #48BF84</div>
                  <div className="text-wrapper-11">RGB: 72, 191, 132</div>
                  <p className="text-wrapper-11">CMYK: 62, 0, 31, 25</p>
                </div>
              </div>
            </div>
            <div className="swatch-tertiary-grey">
              <div className="frame-11">
                <div className="text-wrapper-12">Grey</div>
                <div className="frame-12">
                  <div className="text-wrapper-13">Hex: #A4969B</div>
                  <div className="text-wrapper-13">RGB: 164, 150, 155</div>
                  <p className="text-wrapper-13">CMYK: 0, 9, 5, 36</p>
                </div>
              </div>
            </div>
            <div className="swatch-neutral-clean">
              <div className="frame-11">
                <div className="text-wrapper-14">Clean Sheet</div>
                <div className="frame-12">
                  <div className="text-wrapper-15">Hex: #FFFFFF</div>
                  <p className="RGB">
                    <span className="span">RGB: 255, </span>
                    <span className="text-wrapper-16">255</span>
                    <span className="span">, </span>
                    <span className="text-wrapper-16">255</span>
                  </p>
                  <p className="text-wrapper-15">CMYK: 0, 0, 0, 0</p>
                </div>
              </div>
            </div>
            <div className="swatch-neuteral">
              <div className="frame-11">
                <div className="text-wrapper-10">Confidence</div>
                <div className="frame-12">
                  <div className="text-wrapper-17">Hex: #141414</div>
                  <div className="text-wrapper-17">RGB: 20, 20, 20</div>
                  <p className="text-wrapper-17">CMYK: 0, 0, 0, 92</p>
                </div>
              </div>
            </div>
            <div className="swatch-UI-essential">
              <div className="frame-11">
                <div className="text-wrapper-10">Red</div>
                <div className="frame-12">
                  <div className="text-wrapper-18">Hex: #F25F5C</div>
                  <div className="text-wrapper-18">RGB: 164, 150, 155</div>
                  <p className="text-wrapper-18">CMYK: 0, 61, 62, 5</p>
                </div>
              </div>
            </div>
            <div className="swatch-UI-essential-2">
              <div className="frame-14">
                <div className="text-wrapper-19">Yellow</div>
                <div className="frame-12">
                  <div className="text-wrapper-20">Hex: #F7DD72</div>
                  <div className="text-wrapper-20">RGB: 247, 221, 114</div>
                  <p className="text-wrapper-20">CMYK: 0, 11, 54, 3</p>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-2">
            <div className="text-wrapper-21">Visual Language</div>
            <div className="text-wrapper-21">Visual Language</div>
          </div>
          <div className="frame-15">
            <div className="overlap-group-3">
              <div className="overlap-3">
                <div className="text-wrapper-22">Aa</div>
                <div className="text-wrapper-23">Plus Jakarta Sans</div>
              </div>
              <div className="text-wrapper-24">
                AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
              </div>
            </div>
          </div>
        </div>
        <p className="text-wrapper-25">
          Faster qualifications and a seamlessly integrated digitised
          verification process for swift disbursals
        </p>
        <img
          className="permission"
          alt="Permission"
          src="/img/permission.png"
        />
        <div className="purpose">
          <div className="overlap-4">
            <img className="vector" alt="Vector" src="/img/vector-26.svg" />
            <Indicator
              className="indicator-instance"
              homeClassName="design-component-instance-node"
              theme="light"
            />
            <div className="frame-16">
              <div className="text-wrapper-26">Check Eligibility</div>
            </div>
            <div className="frame-17">
              <Checkbox
                className="checkbox-instance"
                stateProp="enabled"
                states="/img/states-1.png"
                statesClassName="checkbox-2"
                variant="primary"
              />
              <p className="by-continuing-you">
                <span className="text-wrapper-27">
                  By continuing, you agree to our{" "}
                </span>
                <span className="text-wrapper-28">Terms</span>
                <span className="text-wrapper-27">
                  . and confirm that you have read our{" "}
                </span>
                <span className="text-wrapper-29">Privacy Policy.</span>
              </p>
            </div>
          </div>
          <div className="top-app-bar">
            <div className="leading-icon-wrapper">
              <LeadingIcon2 className="leading-icon" color="#141414" />
            </div>
          </div>
          <div className="system-bar">
            <div className="time">9:30</div>
            <div className="right-icons">
              <div className="wifi">
                <div className="path-wrapper">
                  <img className="path" alt="Path" src="/img/path-27.svg" />
                </div>
                <img className="path-2" alt="Path" src="/img/path-1.png" />
              </div>
              <img className="signal" alt="Signal" src="/img/signal-9.png" />
              <img className="battery" alt="Battery" src="/img/battery-9.svg" />
            </div>
          </div>
          <p className="text-wrapper-30">What’s the purpose your loan?</p>
          <InputField
            className="input-field-instance"
            divClassName="design-component-instance-node-2"
            ellipseClassName="design-component-instance-node-3"
            frameClassName="input-field-2"
            stateProp="default"
            text="Pan Number"
          />
          <div className="pincode">
            <p className="lastly-what-s-your">
              <span className="text-wrapper-31">
                Lastly, What’s your Pan Number
              </span>
              <span className="text-wrapper-32">*</span>
            </p>
          </div>
          <div className="frame-18">
            <img className="info" alt="Info" src="/img/info-3.png" />
            <p className="rest-easy-this-is-a">
              Rest Easy, this is a soft pull&nbsp;&nbsp;and won&#39;t hurt your
              credit score
            </p>
          </div>
          <InputFieldAction
            className="input-field-action-instance"
            divClassName="design-component-instance-node-2"
            ellipseClassName="design-component-instance-node-3"
            expandCircleDown="/img/expand-circle-down.png"
            expandCircleDownClassName="input-field-action-3"
            frameClassName="input-field-action-2"
            stateProp="default"
          />
          <div className="name">
            <div className="pincode-2">
              <div className="text-wrapper-33">Name (As on Pancard)</div>
            </div>
            <div className="frame-19">
              <div className="text-wrapper-34">Enter Your Name</div>
            </div>
          </div>
        </div>
        <img
          className="front-view-flying"
          alt="Front view flying"
          src="/img/front-view-flying-three-angle-iphone-12-pro-mockup-template-64d2.png"
        />
        <div className="frame-20">
          <div className="text-wrapper-35">Simplified Onboarding</div>
          <p className="p">
            Traditional banking applications take a lot of data inputs before
            even getting to actual business - Vister simplifies this by just
            asking for the bare minimum with a soft pull to figure out your
            credit worthiness.
          </p>
        </div>
        <div className="frame-21">
          <div className="text-wrapper-36">Interactive visual elements</div>
          <p className="p">
            Not all process needs to look boring - We added a interactive
            progress bars and animated loaders to keep the user engaged
            throughout the soft pull.
          </p>
        </div>
        <div className="congratulations">
          <div className="frame-22">
            <div className="overlap-5">
              <img className="vector-2" alt="Vector" src="/img/vector-16.png" />
              <img className="vector-3" alt="Vector" src="/img/vector-16.png" />
              <img className="vector-4" alt="Vector" src="/img/vector-16.png" />
              <img className="image" alt="Image" src="/img/vector-16.png" />
              <img className="image-2" alt="Image" src="/img/vector-16.png" />
            </div>
            <div className="overlap-6">
              <div className="overlap-7">
                <div className="group-2">
                  <img
                    className="ellipse-4"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-5" />
                </div>
                <div className="group-3">
                  <img
                    className="ellipse-6"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-7" />
                </div>
                <div className="group-4">
                  <img
                    className="ellipse-8"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-7" />
                </div>
                <div className="group-5">
                  <img
                    className="ellipse-9"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-10" />
                </div>
                <div className="group-6">
                  <div className="overlap-group-4">
                    <img
                      className="ellipse-11"
                      alt="Ellipse"
                      src="/img/vector-16.png"
                    />
                    <img
                      className="ellipse-12"
                      alt="Ellipse"
                      src="/img/vector-16.png"
                    />
                  </div>
                </div>
                <div className="group-7">
                  <img
                    className="ellipse-6"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-7" />
                </div>
                <div className="group-8">
                  <img
                    className="ellipse-13"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-14" />
                </div>
                <div className="group-9">
                  <img
                    className="ellipse-8"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-7" />
                </div>
                <div className="group-10">
                  <img
                    className="ellipse-15"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-16" />
                </div>
                <div className="group-11">
                  <img
                    className="ellipse-17"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-18" />
                </div>
                <div className="group-12">
                  <img
                    className="ellipse-13"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-14" />
                </div>
                <div className="group-13">
                  <img
                    className="ellipse-13"
                    alt="Ellipse"
                    src="/img/vector-16.png"
                  />
                  <div className="ellipse-18" />
                </div>
              </div>
              <div className="group-14">
                <img
                  className="ellipse-19"
                  alt="Ellipse"
                  src="/img/vector-16.png"
                />
                <div className="ellipse-20" />
              </div>
              <div className="group-15">
                <img
                  className="ellipse-21"
                  alt="Ellipse"
                  src="/img/vector-16.png"
                />
                <div className="ellipse-10" />
              </div>
            </div>
          </div>
          <div className="text-wrapper-37">Transer</div>
          <div className="overlap-8">
            <div className="frame-23">
              <div className="frame-24">
                <div className="text-wrapper-38">Congratualtions!</div>
                <p className="text-wrapper-39">
                  You qualify for a personal loan of
                </p>
              </div>
              <div className="text-wrapper-40">5,00,000</div>
            </div>
            <div className="rectangle-2" />
          </div>
          <div className="vector-wrapper">
            <img className="vector-5" alt="Vector" src="/img/vector-16.png" />
          </div>
          <img className="image-3" alt="Image" src="/img/image-17.png" />
          <div className="rectangle-3" />
          <div className="overlap-9">
            <img className="polygon" alt="Polygon" src="/img/polygon-3.svg" />
            <div className="rectangle-4" />
          </div>
          <div className="ellipse-22" />
          <p className="text-wrapper-41">
            Ensuring your credit score is intact
          </p>
        </div>
        <img
          className="flying-two-side"
          alt="Flying two side"
          src="/img/flying-two-side-iphone-x-mockup-template-64d2d02bcc8e687dc8fc335.png"
        />
        <div className="frame-25">
          <div className="loan">
            <div className="text-wrapper-42">Transer</div>
            <div className="overlap-10">
              <div className="frame-26">
                <div className="frame-27">
                  <div className="text-wrapper-43">24</div>
                  <div className="text-wrapper-44">Months</div>
                </div>
                <div className="frame-28">
                  <div className="text-wrapper-45">3</div>
                  <div className="text-wrapper-46">Months</div>
                </div>
                <div className="frame-29">
                  <div className="text-wrapper-47">6</div>
                  <div className="text-wrapper-48">Months</div>
                </div>
                <div className="frame-30">
                  <div className="text-wrapper-49">12</div>
                  <div className="text-wrapper-50">Months</div>
                  <div className="frame-31">
                    <div className="text-wrapper-51">Recomended</div>
                  </div>
                </div>
                <div className="frame-28">
                  <div className="text-wrapper-52">18</div>
                  <div className="text-wrapper-53">Months</div>
                </div>
                <div className="frame-32">
                  <div className="text-wrapper-54">24</div>
                  <div className="text-wrapper-46">Months</div>
                </div>
                <div className="frame-27">
                  <div className="text-wrapper-43">96</div>
                  <div className="text-wrapper-44">Months</div>
                </div>
              </div>
              <div className="text-wrapper-55">Choose EMI Plan</div>
              <Indicator
                className="indicator-2"
                homeClassName="indicator-3"
                theme="light"
              />
              <div className="card">
                <div className="frame-33">
                  <div className="frame-34">
                    <div className="text-wrapper-56">Loan Details</div>
                    <img className="CTA" alt="Cta" src="/img/cta.png" />
                  </div>
                </div>
                <div className="frame-35">
                  <div className="frame-36">
                    <div className="frame-37">
                      <div className="frame-38">
                        <div className="text-wrapper-57">Loan Amount</div>
                        <div className="text-wrapper-57">₹ 2,25,000</div>
                      </div>
                      <div className="frame-39">
                        <div className="text-wrapper-57">Processing Fee</div>
                        <div className="text-wrapper-57"> ₹ 0</div>
                      </div>
                      <div className="frame-39">
                        <div className="text-wrapper-57">GST</div>
                        <div className="text-wrapper-57">- ₹1,500</div>
                      </div>
                      <div className="frame-38">
                        <div className="text-wrapper-58">
                          Amount Transferable
                        </div>
                        <div className="text-wrapper-58">₹ 2,23,500</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-40">
                  <div className="frame-41">
                    <div className="frame-36">
                      <div className="frame-38">
                        <div className="text-wrapper-57">EMI Tenure</div>
                        <div className="text-wrapper-57">12 Months</div>
                      </div>
                      <div className="frame-39">
                        <div className="text-wrapper-57">Interest Payable</div>
                        <div className="text-wrapper-57">2,50,902</div>
                      </div>
                      <div className="frame-39">
                        <div className="text-wrapper-57">
                          Total Loan Repayment
                        </div>
                        <div className="text-wrapper-57">₹8,50,902</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-42">
                  <div className="frame-39">
                    <div className="frame-43">
                      <div className="text-wrapper-59">Monthly EMI</div>
                      <div className="text-wrapper-60">32,000/-</div>
                    </div>
                    <div className="frame-43">
                      <div className="text-wrapper-59">1st EMI On</div>
                      <div className="text-wrapper-60">10-07-2022</div>
                    </div>
                    <div className="frame-43">
                      <div className="text-wrapper-61">Interest</div>
                      <div className="text-wrapper-60">15.00%</div>
                    </div>
                  </div>
                  <div className="frame-39">
                    <div className="frame-44">
                      <div className="text-wrapper-62">Transfer Amount</div>
                      <p className="element-2">
                        <span className="text-wrapper-63">₹ </span>
                        <span className="text-wrapper-64"> 2,23,500</span>
                      </p>
                    </div>
                    <div className="frame-45">
                      <div className="text-wrapper-65">Get Funds Now</div>
                      <img
                        className="arrow-right-alt"
                        alt="Arrow right alt"
                        src="/img/arrow-right-alt.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="top-app-bar-2">
              <div className="frame-46">
                <LeadingIcon2 className="leading-icon-1" color="#141414" />
              </div>
            </div>
            <div className="system-bar-2">
              <div className="time-2">9:30</div>
              <div className="right-icons-2">
                <div className="wifi-2">
                  <div className="div-3">
                    <img className="path-3" alt="Path" src="/img/path-24.svg" />
                  </div>
                  <img className="path-4" alt="Path" src="/img/path-1.png" />
                </div>
                <img
                  className="signal-2"
                  alt="Signal"
                  src="/img/signal-8.png"
                />
                <img
                  className="battery-2"
                  alt="Battery"
                  src="/img/battery-8.svg"
                />
              </div>
            </div>
            <div className="frame-47">
              <div className="text-wrapper-66">You’re sanctioned</div>
              <div className="text-wrapper-67">₹ 2,25,000</div>
            </div>
          </div>
          <div className="self-declared-input">
            <div className="overlap-11">
              <div className="forms">
                <div className="div-4">
                  <div className="div-4">
                    <div className="text-wrapper-68">Name (As on Pancard)</div>
                  </div>
                  <div className="frame-48">
                    <div className="text-wrapper-69">Santosh Kalaimani</div>
                  </div>
                </div>
                <div className="occupation-type">
                  <div className="pincode-3">
                    <div className="text-wrapper-70">Occupation Type</div>
                  </div>
                  <div className="frame-49">
                    <div className="frame-50">
                      <RadioButton
                        check="checked"
                        className="radio-button-instance"
                        ellipseClassName="radio-button-2"
                      />
                      <div className="text-wrapper-71">Salaried</div>
                    </div>
                    <div className="frame-50">
                      <RadioButton
                        check="uncheck"
                        className="radio-button-instance"
                      />
                      <div className="text-wrapper-71">Self Employed</div>
                    </div>
                  </div>
                </div>
                <div className="div-wrapper-2">
                  <div className="text-wrapper-72">Employer’s Name</div>
                </div>
                <div className="div-5">
                  <div className="pincode-3">
                    <div className="text-wrapper-70">Your Monthly Income</div>
                  </div>
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-72">Enter Your Income</div>
                  </div>
                </div>
                <div className="monthly-expense">
                  <div className="div-5">
                    <div className="pincode-3">
                      <div className="text-wrapper-70">
                        Your Monthly Expenses
                      </div>
                    </div>
                    <div className="div-wrapper-2">
                      <div className="text-wrapper-72">Enter Your Expense</div>
                    </div>
                  </div>
                  <div className="card-2">
                    <img className="info-2" alt="Info" src="/img/info-2.png" />
                    <p className="text-wrapper-73">
                      Monthly expense which includes all EMIs, rent and others.
                    </p>
                  </div>
                </div>
                <div className="address">
                  <div className="pincode-3">
                    <div className="text-wrapper-70">
                      Add Your Current Address
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="text-wrapper-74">Address</div>
                    <img
                      className="leading-icon-1"
                      alt="My location"
                      src="/img/my-location.png"
                    />
                  </div>
                </div>
              </div>
              <div className="frame-52">
                <div className="frame-53">
                  <div className="frame-54">
                    <div className="frame-55">
                      <Checkbox
                        className="checkbox-3"
                        stateProp="enabled"
                        states="/img/states.png"
                        statesClassName="checkbox-4"
                        variant="deselected"
                      />
                      <p className="by-continuing-you-2">
                        <span className="text-wrapper-75">
                          By continuing, you agree to our{" "}
                        </span>
                        <span className="text-wrapper-76">Terms</span>
                        <span className="text-wrapper-75">
                          {" "}
                          and confirm that you have read our{" "}
                        </span>
                        <span className="text-wrapper-77">Privacy Policy</span>
                      </p>
                    </div>
                    <div className="frame-56">
                      <div className="text-wrapper-65">Continue</div>
                    </div>
                  </div>
                </div>
                <Indicator
                  className="indicator-4"
                  homeClassName="indicator-5"
                  theme="light"
                />
              </div>
            </div>
            <div className="frame-57">
              <div className="system-bar-3">
                <div className="time-3">9:30</div>
                <div className="right-icons-2">
                  <div className="wifi-3">
                    <div className="img-wrapper">
                      <img
                        className="path-3"
                        alt="Path"
                        src="/img/path-21.svg"
                      />
                    </div>
                  </div>
                  <img
                    className="signal-2"
                    alt="Signal"
                    src="/img/signal-7.png"
                  />
                  <img
                    className="battery-2"
                    alt="Battery"
                    src="/img/battery-7.svg"
                  />
                </div>
              </div>
              <div className="top-app-bar-2">
                <div className="frame-46">
                  <IconsArrowBack />
                </div>
              </div>
              <div className="frame-58">
                <div className="headline">Self-declared input</div>
                <p className="text-wrapper-78">
                  Tell us a little bit about you, this allows us to get you best
                  rates and terms.
                </p>
              </div>
            </div>
          </div>
          <div className="identity">
            <Indicator
              className="indicator-6"
              homeClassName="indicator-5"
              theme="light"
            />
            <div className="frame-59">
              <div className="text-wrapper-79">Start</div>
            </div>
            <div className="frame-60">
              <div className="frame-61">
                <div className="overlap-group-5">
                  <div className="rectangle-5" />
                  <p className="text-wrapper-80">
                    Speak out loud and tilt your head to right and left
                  </p>
                </div>
              </div>
              <div className="frame-62">
                <div className="rectangle-6" />
                <p className="text-wrapper-81">
                  Finish actions within 25 seconds
                </p>
              </div>
            </div>
            <img className="line-2" alt="Line" src="/img/line-70.svg" />
            <div className="overlap-wrapper">
              <div className="overlap-12">
                <div className="ellipse-23" />
                <div className="ellipse-24" />
                <img className="frame-63" alt="Frame" src="/img/frame.svg" />
                <img
                  className="vector-6"
                  alt="Vector"
                  src="/img/vector-17.svg"
                />
                <img
                  className="vector-7"
                  alt="Vector"
                  src="/img/vector-18.svg"
                />
                <img
                  className="vector-8"
                  alt="Vector"
                  src="/img/vector-21.svg"
                />
                <img
                  className="vector-9"
                  alt="Vector"
                  src="/img/vector-22.svg"
                />
              </div>
            </div>
            <div className="frame-64">
              <div className="system-bar-4">
                <div className="time-3">9:30</div>
                <div className="right-icons-2">
                  <div className="div-3">
                    <div className="overlap-group-6">
                      <img className="div-3" alt="Path" src="/img/path-1.png" />
                    </div>
                    <div className="div-3">
                      <img
                        className="path-3"
                        alt="Path"
                        src="/img/path-18.svg"
                      />
                    </div>
                  </div>
                  <img
                    className="signal-2"
                    alt="Signal"
                    src="/img/signal-6.png"
                  />
                  <img
                    className="battery-2"
                    alt="Battery"
                    src="/img/battery-6.svg"
                  />
                </div>
              </div>
              <div className="top-app-bar-3">
                <div className="frame-65">
                  <LeadingIcon2 className="leading-icon-1" color="#141414" />
                  <div className="frame-66">
                    <div className="frame-67">
                      <img
                        className="group-16"
                        alt="Group"
                        src="/img/group.png"
                      />
                      <div className="text-wrapper-82">English</div>
                    </div>
                    <img
                      className="leading-icon-1"
                      alt="Expand more"
                      src="/img/expand-more.png"
                    />
                  </div>
                </div>
              </div>
              <div className="frame-68">
                <div className="headline">Identity Verification</div>
                <p className="text-wrapper-83">
                  Record a short selfie. Let’s make sure nobody’s impersonating
                  you and protect you from frauds.
                </p>
              </div>
            </div>
          </div>
          <div className="UPI-validation">
            <Indicator
              className="indicator-6"
              homeClassName="indicator-5"
              theme="light"
            />
            <div className="overlap-13">
              <div className="system-bar-5">
                <div className="time-4">9:30</div>
                <div className="right-icons-3">
                  <div className="wifi-4">
                    <div className="div-3">
                      <img
                        className="path-3"
                        alt="Path"
                        src="/img/path-15.svg"
                      />
                    </div>
                    <img className="path-5" alt="Path" src="/img/path-1.png" />
                  </div>
                  <img
                    className="signal-2"
                    alt="Signal"
                    src="/img/signal-5.png"
                  />
                  <img
                    className="battery-2"
                    alt="Battery"
                    src="/img/battery-5.svg"
                  />
                </div>
              </div>
              <div className="frame-69">
                <div className="system-bar-6">
                  <div className="time-3">9:30</div>
                  <div className="right-icons-2">
                    <div className="wifi-4">
                      <div className="div-3">
                        <img
                          className="path-3"
                          alt="Path"
                          src="/img/path-12.svg"
                        />
                      </div>
                      <img
                        className="path-5"
                        alt="Path"
                        src="/img/path-1.png"
                      />
                    </div>
                    <img
                      className="signal-2"
                      alt="Signal"
                      src="/img/signal-4.png"
                    />
                    <img
                      className="battery-2"
                      alt="Battery"
                      src="/img/battery-4.svg"
                    />
                  </div>
                </div>
                <div className="top-app-bar-3">
                  <div className="frame-70">
                    <LeadingIcon2 className="leading-icon-1" color="#141414" />
                  </div>
                </div>
                <div className="frame-68">
                  <div className="headline">UPI Validation</div>
                  <p className="text-wrapper-78">
                    Where do you want us to send these funds?
                  </p>
                </div>
              </div>
            </div>
            <div className="frame-71">
              <img className="info-2" alt="Info" src="/img/info-1.png" />
              <p className="text-wrapper-84">
                We will deposit Rs. 1 for free in your bank account to verify
                that the account belongs to you.
              </p>
            </div>
            <div className="frame-59">
              <div className="text-wrapper-79">Continue</div>
            </div>
            <div className="overlap-14">
              <div className="text-wrapper-85">Use Bank Details instead?</div>
              <div className="frame-72">
                <div className="pincode-3">
                  <p className="UPI-id">
                    <span className="text-wrapper-31">UPI Id</span>
                    <span className="text-wrapper-32">*</span>
                  </p>
                </div>
                <div className="frame-73">
                  <div className="text-wrapper-86">
                    For eg. 9987115132@okicici
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-87">Use bank account instead?</div>
          </div>
          <div className="bank-account">
            <div className="overlap-13">
              <div className="system-bar-5">
                <div className="time-4">9:30</div>
                <div className="right-icons-3">
                  <div className="wifi-5">
                    <div className="div-3">
                      <img
                        className="path-3"
                        alt="Path"
                        src="/img/path-9.svg"
                      />
                    </div>
                    <img className="path-6" alt="Path" src="/img/path-1.png" />
                  </div>
                  <img
                    className="signal-2"
                    alt="Signal"
                    src="/img/signal-3.png"
                  />
                  <img
                    className="battery-2"
                    alt="Battery"
                    src="/img/battery-3.svg"
                  />
                </div>
              </div>
              <div className="frame-74">
                <div className="system-bar-7">
                  <div className="time-3">9:30</div>
                  <div className="right-icons-2">
                    <div className="wifi-5">
                      <div className="div-3">
                        <img
                          className="path-3"
                          alt="Path"
                          src="/img/path-6.svg"
                        />
                      </div>
                      <img
                        className="path-6"
                        alt="Path"
                        src="/img/path-1.png"
                      />
                    </div>
                    <img
                      className="signal-2"
                      alt="Signal"
                      src="/img/signal-2.png"
                    />
                    <img
                      className="battery-2"
                      alt="Battery"
                      src="/img/battery-2.svg"
                    />
                  </div>
                </div>
                <div className="top-app-bar-3">
                  <div className="frame-70">
                    <LeadingIcon2 className="leading-icon-1" color="#141414" />
                  </div>
                </div>
                <div className="frame-75">
                  <div className="headline">Bank Account Verification</div>
                  <p className="text-wrapper-88">
                    Where do you want us to send these funds?
                  </p>
                </div>
              </div>
            </div>
            <div className="overlap-15">
              <div className="frame-76">
                <div className="frame-77">
                  <div className="text-wrapper-70">
                    Primary Account Holder Detail
                  </div>
                  <div className="frame-55">
                    <div className="text-wrapper-89">Sanjay Krishnan</div>
                    <div className="frame-78">
                      <div className="text-wrapper-90">Savings Account</div>
                    </div>
                  </div>
                </div>
                <img className="line-3" alt="Line" src="/img/line-71.svg" />
                <div className="form">
                  <div className="frame-79">
                    <img className="info-2" alt="Info" src="/img/info.png" />
                    <p className="text-wrapper-84">
                      We will deposit Rs. 1 for free in your bank account to
                      verify that the account belongs to you.
                    </p>
                  </div>
                  <div className="frame-37">
                    <div className="frame-55">
                      <p className="div-6">
                        <span className="text-wrapper-91">
                          Bank Account Number
                        </span>
                        <span className="text-wrapper-32">*</span>
                      </p>
                    </div>
                    <div className="frame-80">
                      <div className="text-wrapper-92">
                        Enter Your Ac Number
                      </div>
                    </div>
                  </div>
                  <div className="frame-37">
                    <div className="frame-55">
                      <p className="div-6">
                        <span className="text-wrapper-91">
                          Re - Bank Account Number
                        </span>
                        <span className="text-wrapper-32">*</span>
                      </p>
                    </div>
                    <div className="frame-81">
                      <div className="text-wrapper-92">
                        Enter Your Ac Number
                      </div>
                    </div>
                  </div>
                  <div className="frame-37">
                    <div className="frame-55">
                      <div className="text-wrapper-70">Mobile Number</div>
                    </div>
                    <div className="frame-81">
                      <div className="text-wrapper-92">9876564768</div>
                    </div>
                  </div>
                  <div className="frame-37">
                    <div className="frame-55">
                      <p className="div-6">
                        <span className="text-wrapper-91">IFSC Code</span>
                        <span className="text-wrapper-32">*</span>
                      </p>
                    </div>
                    <div className="frame-81">
                      <div className="text-wrapper-92">
                        Enter Your IFSC Code
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-82">
                <div className="frame-83">
                  <div className="frame-36">
                    <div className="frame-56">
                      <div className="text-wrapper-65">Continue</div>
                    </div>
                  </div>
                </div>
                <Indicator
                  className="indicator-4"
                  homeClassName="indicator-5"
                  theme="light"
                />
              </div>
            </div>
          </div>
          <div className="bank-account-2">
            <div className="system-bar-8">
              <div className="time-4">9:30</div>
              <div className="right-icons-3">
                <div className="div-7">
                  <div className="div-3" />
                  <div className="div-7">
                    <img
                      className="path-3"
                      alt="Path"
                      src="/img/battery-1.png"
                    />
                  </div>
                </div>
                <img
                  className="signal-3"
                  alt="Signal"
                  src="/img/battery-1.png"
                />
                <img
                  className="battery-3"
                  alt="Battery"
                  src="/img/battery-1.png"
                />
              </div>
            </div>
            <div className="overlap-16">
              <img
                className="rectangle-7"
                alt="Rectangle"
                src="/img/rectangle.png"
              />
              <div className="text-wrapper-93">
                Wohoo! Fund transfer initiated
              </div>
            </div>
            <p className="sit-back-and-relax">
              Sit back and Relax. The Funds should be reaching&nbsp;&nbsp;your
              account in the next 2-4 hours.
            </p>
            <div className="overlap-17">
              <div className="frame-84">
                <div className="frame-85">
                  <div className="frame-36">
                    <div className="frame-86">
                      <div className="text-wrapper-65">Continue</div>
                    </div>
                  </div>
                </div>
                <Indicator
                  className="indicator-7"
                  homeClassName="indicator-5"
                  theme="light"
                />
              </div>
              <div className="frame-84">
                <div className="frame-85">
                  <div className="frame-36">
                    <div className="frame-86">
                      <div className="text-wrapper-65">Continue</div>
                    </div>
                  </div>
                </div>
                <Indicator
                  className="indicator-7"
                  homeClassName="indicator-5"
                  theme="light"
                />
              </div>
            </div>
          </div>
        </div>
        <img
          className="isometric-iphone"
          alt="Isometric iphone"
          src="/img/isometric-iphone-12-black-mockup-template-64d2d3dbcc8e687dc8fc33.png"
        />
        <div className="frame-87">
          <p className="text-wrapper-94">Managing and repaying’s a breeze.</p>
          <img
            className="loan-history"
            alt="Loan history"
            src="/img/loan-history-1.png"
          />
          <img
            className="payment-details"
            alt="Payment details"
            src="/img/payment-details.png"
          />
          <img
            className="payment-method"
            alt="Payment method"
            src="/img/payment-method-2.png"
          />
        </div>
        <div className="frame-88">
          <div className="text-wrapper-95">The Result</div>
          <p className="text-wrapper-96">
            The result: A high-performance, intuitive product experience with a
            fun and engaging creative expression gave us a whopping 96% adoption
            rate from users 100% of users gave us a massive thumbs-up on the
            overall approach, and the client with a job well done!
          </p>
        </div>
        <div className="mask-group-wrapper">
          <img
            className="mask-group"
            alt="Mask group"
            src="/img/mask-group.png"
          />
        </div>
        <div className="frame-89">
          <div className="text-wrapper-97">Our strategy</div>
          <p className="text-wrapper-98">
            We started with the Lo-fid wireframes, enabling us to draw a clear
            storyline and keep the users involved in all stages to get feedback
            instantly, iterate rapidly and test the hypothesis without breaking
            the bank.
          </p>
          <div className="text-wrapper-99">Genius!</div>
        </div>
        <div className="frame-90">
          <div className="overlap-18">
            <img
              className="soft-pull-filled"
              alt="Soft pull filled"
              src="/img/soft-pull-filled.png"
            />
            <img
              className="congratulations-2"
              alt="Congratulations"
              src="/img/congratulations.png"
            />
            <img className="loan-2" alt="Loan" src="/img/loan.png" />
            <img
              className="self-declared-input-2"
              alt="Self declared input"
              src="/img/self-declared-input.png"
            />
            <img
              className="payment-scatchcard"
              alt="Payment scatchcard"
              src="/img/payment-scatchcard.png"
            />
            <img
              className="identity-2"
              alt="Identity"
              src="/img/identity-verification-1.png"
            />
            <img
              className="identity-3"
              alt="Identity"
              src="/img/identity-verification.png"
            />
            <img
              className="loan-history-2"
              alt="Loan history"
              src="/img/loan-history.png"
            />
            <img
              className="payment-method-2"
              alt="Payment method"
              src="/img/payment-method-1.png"
            />
            <div className="permission-2">
              <div className="overlap-19">
                <div className="ellipse-25" />
                <div className="frame-91">
                  <div className="few-permissions-to-wrapper">
                    <p className="few-permissions-to">
                      Few Permissions <br />
                      to serve you better
                    </p>
                  </div>
                  <div className="frame-92">
                    <div className="frame-93">
                      <div className="frame-94">
                        <div className="overlap-group-7">
                          <img
                            className="vector-10"
                            alt="Vector"
                            src="/img/vector-2.svg"
                          />
                          <div className="image-4" />
                        </div>
                      </div>
                      <div className="frame-95">
                        <div className="text-wrapper-100">Location</div>
                        <p className="text-wrapper-101">
                          To check servicablity in your area
                        </p>
                      </div>
                    </div>
                    <div className="frame-93">
                      <div className="frame-94">
                        <img
                          className="vector-11"
                          alt="Vector"
                          src="/img/vector-1.svg"
                        />
                      </div>
                      <div className="frame-96">
                        <div className="text-wrapper-100">Device</div>
                        <p className="text-wrapper-102">
                          Access other application that you use so that we can
                          provided you best product
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="system-bar-9">
                  <div className="time-5">9:30</div>
                  <div className="right-icons-4">
                    <div className="wifi-6">
                      <div className="path-wrapper">
                        <img
                          className="path-7"
                          alt="Path"
                          src="/img/path.svg"
                        />
                      </div>
                      <img
                        className="path-8"
                        alt="Path"
                        src="/img/path-1.png"
                      />
                    </div>
                    <div className="overlap-20">
                      <img
                        className="signal-4"
                        alt="Signal"
                        src="/img/signal.png"
                      />
                      <img
                        className="battery-4"
                        alt="Battery"
                        src="/img/battery.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="frame-97">
                  <div className="overlap-21">
                    <div className="group-17">
                      <div className="overlap-22">
                        <img
                          className="vector-12"
                          alt="Vector"
                          src="/img/vector-8.svg"
                        />
                        <div className="group-18">
                          <div className="overlap-group-8">
                            <img
                              className="vector-13"
                              alt="Vector"
                              src="/img/vector-9.svg"
                            />
                            <img
                              className="vector-14"
                              alt="Vector"
                              src="/img/vector-11.svg"
                            />
                            <img
                              className="vector-15"
                              alt="Vector"
                              src="/img/vector-7.svg"
                            />
                            <img
                              className="vector-16"
                              alt="Vector"
                              src="/img/vector-12.svg"
                            />
                            <img
                              className="ellipse-26"
                              alt="Ellipse"
                              src="/img/ellipse-239.svg"
                            />
                          </div>
                        </div>
                        <img
                          className="vector-17"
                          alt="Vector"
                          src="/img/vector-13.svg"
                        />
                        <img
                          className="vector-18"
                          alt="Vector"
                          src="/img/vector-15-1.svg"
                        />
                      </div>
                    </div>
                    <img
                      className="vector-19"
                      alt="Vector"
                      src="/img/vector-14.svg"
                    />
                    <img
                      className="vector-20"
                      alt="Vector"
                      src="/img/vector-15.svg"
                    />
                  </div>
                </div>
              </div>
              <Indicator className="indicator-8" theme="light" />
              <div className="frame-98">
                <div className="frame-99">
                  <div className="frame-100">
                    <div className="frame-101">
                      <div className="overlap-group-9">
                        <div className="ellipse-27" />
                        <img
                          className="vector-21"
                          alt="Vector"
                          src="/img/vector.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-wrapper-103">Your data is safe with us!</p>
                </div>
                <div className="frame-102">
                  <div className="text-wrapper-104">Let’s Go!</div>
                </div>
              </div>
            </div>
            <img
              className="UPI-validation-2"
              alt="Upi validation"
              src="/img/upi-validation.png"
            />
            <img
              className="payment-method-3"
              alt="Payment method"
              src="/img/payment-method.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const RadioButton = ({ check, className, ellipseClassName }) => {
  return (
    <div className={`radio-button ${check} ${className}`}>
      {check === "checked" && <div className={`ellipse-3 ${ellipseClassName}`} />}
    </div>
  );
};

RadioButton.propTypes = {
  check: PropTypes.oneOf(["checked", "uncheck"]),
};

/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { LeadingIcon2 } from "../../icons/LeadingIcon2";
import "./style.css";

export const IconsArrowBack = () => {
  return <LeadingIcon2 className="leading-icon-2" color="#1F1F1F" />;
};

/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Checkbox = ({ stateProp, variant, className, statesClassName, states = "/img/states-11.svg" }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "enabled",
    variant: variant || "primary",
  });

  return (
    <div
      className={`checkbox ${state.variant} ${state.state} ${className}`}
      onClick={() => {
        dispatch("click");
      }}
    >
      <img
        className={`states ${statesClassName}`}
        alt="States"
        src={
          ["diabled", "enabled"].includes(state.state)
            ? states
            : state.variant === "primary" && state.state === "hover"
            ? "/img/states-10.svg"
            : state.variant === "secondary" && ["hover", "pressed"].includes(state.state)
            ? "/img/states-2.svg"
            : state.variant === "primary" && state.state === "focused"
            ? "/img/states-7.svg"
            : state.variant === "deselected" && state.state === "focused"
            ? "/img/states-6.svg"
            : state.variant === "secondary" && state.state === "focused"
            ? "/img/states-5.svg"
            : state.variant === "primary" && state.state === "pressed"
            ? "/img/states-4.svg"
            : "/img/states-3.svg"
        }
      />
    </div>
  );
};

function reducer(state, action) {
  if (state.state === "enabled" && state.variant === "primary") {
    switch (action) {
      case "click":
        return {
          state: "diabled",
          variant: "primary",
        };
    }
  }

  if (state.state === "enabled" && state.variant === "deselected") {
    switch (action) {
      case "click":
        return {
          state: "enabled",
          variant: "primary",
        };
    }
  }

  return state;
}

Checkbox.propTypes = {
  stateProp: PropTypes.oneOf(["enabled", "focused", "pressed", "diabled", "hover"]),
  variant: PropTypes.oneOf(["primary", "deselected", "secondary"]),
  states: PropTypes.string,
};
